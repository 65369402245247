import { IDeleteButton } from './DeleteButton.types';
import { useDispatch } from 'react-redux';
import {
  updateDeleteModalState,
} from '@app/redux/features/modalsSlice';

import { ReactComponent as Delete } from '@images/trash.svg'

const DeleteButton = ({ unit, callback }: IDeleteButton) => {
  const dispatch = useDispatch();

  const handleOpenConfirmation = () => {
    dispatch(
      updateDeleteModalState({
        isOpen: true,
        title: 'Delete',
        unitId: unit.id,
      })
    );
    callback && callback();
  };

  return (
    <div className='action-item' onClick={handleOpenConfirmation}>
      <Delete className='action-item__icon' />
      Delete
    </div>
  );
};

export default DeleteButton;
