import { createContext, useContext, useMemo } from 'react';
import { WorkspaceContextValueType } from './types';
import { Workspace } from '@entities/models/workspace';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  createNewWorkspace,
  getWorkspaces,
} from '@app/services/workspace.service';
import {
  selectCurrentUser,
  setWorkspace as setWorkspaceItemGlobalValue,
  setWorkspaces as setWorkspacesGlobalValue,
} from '@app/redux/features/userSlice';

const WorkspaceContext = createContext<WorkspaceContextValueType>({
  workspace: null,
  workspaces: [],
  switchWorkspace: (ws: Workspace) => {},
  fetchAndSetWorkspace: async (workspaceId: string) => {},
  fetchWorkspaces: async () => [],
  resetWorkspaces: async () => {},
  workspacesLoaded: false,
  createWorkspace: async (name: string) => {},
} as WorkspaceContextValueType);

export const WorkspaceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const userState = useSelector(selectCurrentUser);
  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const dispatch = useDispatch();

  const workspacesLoaded = useRef(userState.workspaces.length > 0);
  const areWorkspacesLoading = useRef(false);

  const fetchWorkspaces = useCallback(async () => {
    try {
      areWorkspacesLoading.current = true;
      const fetchedWorkspaces = await getWorkspaces();

      dispatch(setWorkspacesGlobalValue(fetchedWorkspaces));
      workspacesLoaded.current = true;

      return fetchedWorkspaces;
    } catch (error) {
      console.log(`Error during loading workspaces: ${error}`);
      return [];
    } finally {
      areWorkspacesLoading.current = false;
    }
  }, []);

  const setCurrentWorkspace = useCallback(() => {
    if (userState.workspace) {
      return setWorkspace(userState.workspace);
    }

    const workspaceId = localStorage.getItem('workspaceId');

    if (!workspaceId) {
      dispatch(setWorkspaceItemGlobalValue(userState.workspaces[0]));
      return setWorkspace(userState.workspaces[0]);
    }

    const workspace = userState.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    );

    if (!workspace) {
      dispatch(setWorkspaceItemGlobalValue(userState.workspaces[0]));
      return setWorkspace(userState.workspaces[0]);
    }

    dispatch(setWorkspaceItemGlobalValue(workspace));
    setWorkspace(workspace);
  }, [userState]);

  const initWorkSpaces = useCallback(async () => {
    if (!userState || !userState.isLoggedIn) {
      setWorkspace(null);
      workspacesLoaded.current = false;
      return;
    }

    if (!workspacesLoaded.current) {
      if (!areWorkspacesLoading.current) {
        await fetchWorkspaces();
      }
    }

    setCurrentWorkspace();
  }, [userState, fetchWorkspaces, setCurrentWorkspace]);

  const resetWorkspaces = useCallback(async () => {
    if (!userState || !userState.isLoggedIn) {
      return;
    }

    workspacesLoaded.current = false;
    await fetchWorkspaces();
    setCurrentWorkspace();
  }, [userState, fetchWorkspaces]);

  const createWorkspace = useCallback(
    async (name: string) => {
      try {
        await createNewWorkspace(name);
      } catch (error) {
        console.log(`Error during creating workspace: ${error}`);
      }
      await resetWorkspaces();
    },
    [resetWorkspaces],
  );

  useEffect(() => {
    initWorkSpaces();
  }, [initWorkSpaces]);

  const switchWorkspace = (ws: Workspace): void => {
    const _workspace = userState.workspaces.find((item) => item.id === ws.id);
    if (_workspace) {
      dispatch(setWorkspaceItemGlobalValue(ws));
      setWorkspace(ws);
    } else {
      dispatch(setWorkspaceItemGlobalValue(userState.workspaces[0]));
      setWorkspace(userState.workspaces[0]);
    }
  };

  const fetchAndSetWorkspace = useCallback(
    async (workspaceId: string) => {
      workspacesLoaded.current = false;

      const workspaces = (await fetchWorkspaces()) || [];
      const ws = workspaces.find((item) => item.id === workspaceId);

      if (ws) {
        dispatch(setWorkspaceItemGlobalValue(ws));
      }
    },
    [fetchWorkspaces],
  );

  const contextValue: WorkspaceContextValueType = useMemo(
    () => ({
      workspace,
      workspaces: userState.workspaces,
      switchWorkspace,
      fetchAndSetWorkspace,
      fetchWorkspaces,
      resetWorkspaces,
      workspacesLoaded: workspacesLoaded.current,
      createWorkspace,
    }),
    [
      workspace,
      userState.workspaces,
      switchWorkspace,
      fetchAndSetWorkspace,
      fetchWorkspaces,
      resetWorkspaces,
      workspacesLoaded,
      createWorkspace,
    ],
  );

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);

  if (typeof context === undefined) {
    throw new Error(
      'useWorkspaceContext must be used within WorkspaceContextProvider',
    );
  }

  return context;
};
