import { track } from '@amplitude/analytics-browser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModalState,
  updateWhiteboardSettingsModalState,
} from '@app/redux/features/modalsSlice';

import { ReactComponent as Icon } from '@images/channelSettings.svg';
import { Unit } from '@entities/models/unit';

const WhiteboardSettingsButton = ({
  unit,
  callback,
}: {
  unit: Unit;
  callback?: any;
}) => {
  const dispatch = useDispatch();
  const { title } = useSelector(getModalState).whiteboardSettingsModalState;

  const openWhiteboardSettingsModal = () => {
    track('whiteboard_settings_popup_opened');
    dispatch(
      updateWhiteboardSettingsModalState({
        isOpen: true,
        title: title,
        unitId: unit.id,
      })
    );
    callback && callback();
  };

  return (
    <>
      <div className='action-item' onClick={openWhiteboardSettingsModal}>
        <Icon className='action-item__icon' />
        Whiteboard settings
      </div>
    </>
  );
};

export default WhiteboardSettingsButton;
