import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Workspace } from '@entities/models/workspace';
import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import WorkspaceRemoved from './WorkspaceRemoved';
import { RemoveWorkspaceMode } from '@pages/Settings/DeleteOrLeave/types';
import { useDispatch } from 'react-redux';
import { logout } from '@app/redux/features/userSlice';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const WorkspaceRemovedContainer = () => {
  const { id: removedWorkspaceId } = useParams();
  const { workspaces, fetchWorkspaces, switchWorkspace } =
    useWorkspaceContext();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate('/login');
  }, []);

  const [deletedWorkspace, setDeletedWorkspace] = useState<Workspace | null>(
    null,
  );

  useLayoutEffect(() => {
    const removedWorkspace = workspaces.find(
      (item) => item.id === removedWorkspaceId,
    );

    if (removedWorkspace) {
      setDeletedWorkspace(removedWorkspace);
    }
  }, [removedWorkspaceId, workspaces]);

  const refreshWorkspacesAndProceed = useCallback(async () => {
    await sleep(2000);
    const refreshedWorkspaces = await fetchWorkspaces();

    if (refreshedWorkspaces.length === 1) {
      switchWorkspace(refreshedWorkspaces[0]);
      return navigate('/home');
    }

    if (refreshedWorkspaces.length > 0) {
      return navigate('/workspaces');
    }

    return handleLogout();
  }, [fetchWorkspaces]);

  useEffect(() => {
    refreshWorkspacesAndProceed();
  }, []);

  const pageMode: RemoveWorkspaceMode = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode') as RemoveWorkspaceMode | null;
    if (!mode) {
      return 'delete';
    }
    return mode;
  }, [location]);

  const label = useMemo(() => {
    const action = pageMode === 'delete' ? 'deleted' : 'left';
    return `You successfully ${action} the ${deletedWorkspace?.name} workspace`;
  }, [pageMode, deletedWorkspace?.name]);

  return <WorkspaceRemoved label={label} />;
};

export default WorkspaceRemovedContainer;
