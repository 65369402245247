import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IUser } from '@entities/models/IUser';
import { Workspace } from '@entities/models/workspace';
import type { RootState } from '../store';

export interface IUserState {
  user: IUser | null;
  workspace: Workspace | null;
  workspaces: Workspace[];
  isLoggedIn: boolean;
}

const initialState: IUserState = {
  user: null,
  workspace: null,
  isLoggedIn: false,
  workspaces: [],
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      Object(window).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'xbzqqob2',
        name: action.payload.name ?? action.payload.email, // Full name
        email: action.payload.email, // Email address
        created_at: Date.now(), // Signup date as a Unix timestamp
      });
    },
    setWorkspace: (state, action: PayloadAction<Workspace>) => {
      if (state.user) {
        state.workspace = action.payload;
        if (action.payload) {
          localStorage.setItem('workspaceId', action.payload.id);
        }
      }
    },
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      if (state.user) {
        state.workspaces = action.payload;
      }
    },
  },
});

export default userSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.me;

export const { logout, setUser, setWorkspace, setWorkspaces } =
  userSlice.actions;
