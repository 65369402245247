import { createSlice } from '@reduxjs/toolkit';

const boardsUpdateSlice = createSlice({
  name: 'boardsUpdate',
  initialState: {
    updateBoard: false,
    statusesString: '',
    subtaskTagsList: [],
    subtaskName: '',
    subtasksList: {},
    complitedSubtaskNumber: {},
    loading: false,
    statusesList: [],
    isCloseMenu: false,
  },
  reducers: {
    setUpdateBoard: (state, action) => {
      state.updateBoard = action.payload;
    },
    setStatusesString: (state, action) => {
      state.statusesString = action.payload;
    },
    setSubtaskTagsList: (state, action) => {
      state.subtaskTagsList = action.payload;
    },
    setSubtaskName: (state, action) => {
      state.subtaskName = action.payload;
    },
    setSubtasksList: (state, action) => {
      state.subtasksList = { ...state.subtasksList, ...action.payload };
    },
    setComplitedSubtaskNumber: (state, action) => {
      state.complitedSubtaskNumber = {
        ...state.complitedSubtaskNumber,
        ...action.payload,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStatusesList: (state, action) => {
      state.statusesList = action.payload;
    },
    setIsCloseMenu: (state, action) => {
      state.isCloseMenu = action.payload;
    },
  },
});

export default boardsUpdateSlice;

export const {
  setUpdateBoard,
  setStatusesString,
  setSubtaskTagsList,
  setSubtaskName,
  setSubtasksList,
  setComplitedSubtaskNumber,
  setLoading,
  setStatusesList,
  setIsCloseMenu,
} = boardsUpdateSlice.actions;

export const updateBoardSelector = (state: any) =>
  state.boardsUpdate.updateBoard;

export const statusesStringSelector = (state: any) =>
  state.boardsUpdate.statusesString;

export const subtaskTagsListSelector = (state: any) =>
  state.boardsUpdate.subtaskTagsList;

export const subtaskNameSelector = (state: any) =>
  state.boardsUpdate.subtaskName;

export const subtasksListSelector = (state: any) =>
  state.boardsUpdate.subtasksList;

export const complitedSubtaskNumberSelector = (state: any) =>
  state.boardsUpdate.complitedSubtaskNumber;

export const loadingSelector = (state: any) => state.boardsUpdate.loading;
export const statusesListSelector = (state: any) =>
  state.boardsUpdate.statusesList;

export const isCloseMenuSelector = (state: any) =>
  state.boardsUpdate.isCloseMenu;
