import ChannelSettingsButton from '@widgets/components/Actions/ChannelSettingsButton/ChannelSettingsButton.component';
import { UnitActionListProps } from '../props';
import CopyLinkButton from '@widgets/components/Actions/CopyLinkButton/CopyLinkButton.component';
import ShareButton from '@widgets/components/Actions/ShareButton/ShareButton.component';
import DeleteButton from '@widgets/components/Actions/DeleteButton/DeleteButton.component';
import { useUnitsContext } from '@app/context/unitsContext/unitsContext';
import { useMemo } from 'react';
import { usePermissionsContext } from '@app/context/permissionsContext/permissionsContext';

const ChannelActionList = ({ unit, callback }: UnitActionListProps) => {
  const { checkIsFullAccessAllowed } = useUnitsContext();
  const { getPermissionsForUnit } = usePermissionsContext();

  const isFullAccessAllowed = useMemo(
    () => checkIsFullAccessAllowed(unit.id),
    [unit.id, checkIsFullAccessAllowed],
  );

  const permissions = useMemo(
    () => getPermissionsForUnit(unit.id),
    [unit.id, getPermissionsForUnit],
  );

  const showSettingsBtn = useMemo(
    () => permissions.includes('edit') || isFullAccessAllowed,
    [permissions, isFullAccessAllowed],
  );

  const showShareBtn = useMemo(
    () => permissions.includes('share') || isFullAccessAllowed,
    [permissions, isFullAccessAllowed],
  );

  const showDeleteBtn = useMemo(
    () => permissions.includes('delete') || isFullAccessAllowed,
    [permissions, isFullAccessAllowed],
  );

  return (
    <>
      {showSettingsBtn && (
        <ChannelSettingsButton
          unit={unit}
          callback={callback}
        />
      )}
      <CopyLinkButton
        unit={unit}
        isCompact={false}
        callback={callback}
      />
      {showShareBtn && (
        <ShareButton
          unit={unit}
          withIcon={true}
          callback={callback}
        />
      )}
      {showDeleteBtn && (
        <DeleteButton
          unit={unit}
          callback={callback}
        />
      )}
    </>
  );
};

export default ChannelActionList;
