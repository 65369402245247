import Modal from '@shared/common/Modal';
import Button from '@shared/common/Button';
import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
  useDeleteWorkspaceMutation,
  useLeaveWorkspaceMutation,
} from '@app/redux/api/workspaceApi';
import { RemoveWorkspaceMode } from './types';

type DeleteOrLeaveConfirmationModalProps = {
  close: () => void;
  isOpen: boolean;
  mode: RemoveWorkspaceMode;
};

export const DeleteOrLeaveConfirmationModal: React.FC<
  DeleteOrLeaveConfirmationModalProps
> = ({ close, isOpen, mode }) => {
  const { workspace } = useWorkspaceContext();

  const [deleteWorkspace, { isLoading: isDeleting }] =
    useDeleteWorkspaceMutation();

  const [leaveWorkspace, { isLoading: isLeaving }] =
    useLeaveWorkspaceMutation();

  const isLoading = mode === 'delete' ? isDeleting : isLeaving;
  const title = mode === 'delete' ? 'Delete workspace' : 'Leave workspace';
  const confirmBtnLabel =
    mode === 'delete' ? 'Delete workspace' : 'Leave workspace';

  const handleDeleteWorkspace = useCallback(() => {
    if (!workspace?.id) {
      return;
    }

    deleteWorkspace({ id: workspace.id || '' })
      .unwrap()
      .then(() => close());
  }, [deleteWorkspace, workspace?.id]);

  const handleLeaveWorkspace = useCallback(() => {
    if (!workspace?.id) {
      return;
    }

    leaveWorkspace({ workspaceId: workspace.id })
      .unwrap()
      .then(() => close());
  }, [deleteWorkspace, workspace?.id]);

  const onConfirmBtnClick = useCallback(() => {
    if (mode === 'delete') {
      handleDeleteWorkspace();
      return;
    }

    handleLeaveWorkspace();
  }, [mode, handleDeleteWorkspace]);

  const [modalClass, setModalClass] = useState<string>('invisible');

  useLayoutEffect(() => {
    if (!isOpen) {
      return setModalClass('invisible');
    }
    setTimeout(() => {
      setModalClass('');
    }, 0);
  }, [isOpen]);

  if (!workspace) return null;

  return (
    <Modal
      modalIsOpen={isOpen}
      closeModal={close}
      closeButton={false}
      userClassName={modalClass}
    >
      <div
        className='text-20-16 font-medium text-text90'
        contentEditable='false'
      >
        {title}
      </div>
      <div className='w-96 mt-5 text-base text-text70'>
        This action cannot be undone. This will permanently delete your entire
        account. All private workspaces will be deleted, and you will be removed
        from all shared workspaces.
      </div>
      <div className='mt-4 font-medium text-16-28 text-errorText'>
        {workspace.name}
      </div>
      <div className='flex mt-10 items-center'>
        <Button
          label='Cancel'
          styleType='small-gray'
          onClick={close}
          className='w-full mr-3'
        />
        <Button
          label={confirmBtnLabel}
          className='!text-errorText w-full'
          styleType='small-gray'
          onClick={onConfirmBtnClick}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </div>
    </Modal>
  );
};
