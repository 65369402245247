import { createSlice } from '@reduxjs/toolkit';
import data from '../../../widgets/editor/plugins/blocks/Kanban/data.json';

const boardsSlice = createSlice({
  name: 'boards',
  initialState: data.boards,
  reducers: {
    addBoard: (state: any, action) => {
      const isActive = state.length > 0 ? false : true;
      const payload = action.payload;
      const board = {
        name: payload.name,
        isActive: payload.isActive,
        columns: payload.columns,
        key: payload.key,
      };
      board.columns = payload.newColumns;
      state.push(board);
    },
    editBoard: (state: any, action) => {
      const payload = action.payload;
      const board: any = state.find((board: any) => board.isActive);
      board.name = payload.name;
      board.columns = payload.newColumns;
      board.key = payload.key
      state.splice(state.indexOf(board), 1, board);
    },
    deleteBoard: (state: any) => {
      const board = state.find((board: any) => board.isActive);
      state.splice(state.indexOf(board), 1);
    },
    deleteAllBoards : (state:any) => {
      state.splice(0, state.length)
    },
    setBoardActive: (state, action) => {
      state.map((board: any, index) => {
        index === action.payload.index
          ? (board.isActive = true)
          : (board.isActive = false);
        return board;
      });
    },
    addTask: (state, action) => {
      const {
        title,
        status,
        description,
        subtasks,
        newColIndex,
        usePriority,
        dueDate,
        assignPerson,
      } = action.payload;
      const task = {
        title,
        description,
        subtasks,
        status,
        usePriority,
        dueDate,
        assignPerson,
      };
      const board: any = state.find((board: any) => board.isActive);
      const column = board.columns.find(
        (col: any, index: any) => index === newColIndex
      );
      column.tasks.push(task);
    },
    editTask: (state, action) => {
      const {
        title,
        status,
        description,
        subtasks,
        prevColIndex,
        newColIndex,
        taskIndex,
        usePriority,
        dueDate,
        assignPerson,
      } = action.payload;
      const board: any = state.find((board: any) => board.isActive);
      const column = board.columns.find(
        (col: any, index: any) => index === prevColIndex
      );
      const task = column.tasks.find(
        (task: any, index: any) => index === taskIndex
      );
      task.title = title;
      task.status = status;
      task.description = description;
      task.subtasks = subtasks;
      task.usePriority = usePriority;
      task.dueDate = dueDate;
      task.assignPerson = assignPerson;

      if (prevColIndex === newColIndex) return;
      column.tasks = column.tasks.filter(
        (task: any, index: any) => index !== taskIndex
      );
      const newCol: any = board?.columns.find(
        (col: any, index: any) => index === newColIndex
      );
      newCol.tasks.push(task);
    },
    dragTask: (state, action) => {
      const { colIndex, prevColIndex, taskIndex } = action.payload;
      const board: any = state.find((board: any) => board.isActive);
      const prevCol = board.columns.find(
        (col: any, i: any) => i === prevColIndex
      );
      const task = prevCol.tasks.splice(taskIndex, 1)[0];
      board.columns.find((col: any, i: any) => i === colIndex).tasks.push(task);
    },
    setSubtaskCompleted: (state: any, action) => {
      const payload = action.payload;
      const board: any = state.find((board: any) => board.isActive);
      const col = board.columns.find(
        (col: any, i: any) => i === payload.colIndex
      );
      const task = col.tasks.find(
        (task: any, i: any) => i === payload.taskIndex
      );
      const subtask = task.subtasks.find(
        (subtask: any, i: any) => i === payload.index
      );
      subtask.isCompleted = !subtask.isCompleted;
    },
    setTaskStatus: (state: any, action) => {
      const payload = action.payload;
      const board = state.find((board: any) => board.isActive);
      const columns = board.columns;
      const col = columns.find((col: any, i: any) => i === payload.colIndex);
      if (payload.colIndex === payload.newColIndex) return;
      const task = col.tasks.find(
        (task: any, i: any) => i === payload.taskIndex
      );
      task.status = payload.status;
      col.tasks = col.tasks.filter(
        (task: any, i: any) => i !== payload.taskIndex
      );
      const newCol = columns.find(
        (col: any, i: any) => i === payload.newColIndex
      );
      newCol.tasks.push(task);
    },
    setTaskPriority: (state: any, action) => {
      const payload = action.payload;
      const board = state.find((board: any) => board.isActive);
      const columns = board.columns;
      const col = columns.find((col: any, i: any) => i === payload.colIndex);
      if (payload.colIndex === payload.newColIndex) return;
      const task = col.tasks.find(
        (task: any, i: any) => i === payload.taskIndex
      );
      task.usePriority = payload.usePriority;
      col.tasks = col.tasks.filter(
        (task: any, i: any) => i !== payload.taskIndex
      );
      const newCol = columns.find(
        (col: any, i: any) => i === payload.newColIndex
      );
      newCol.tasks.push(task);
    },
    deleteTask: (state: any, action) => {
      const payload = action.payload;

      const board = state.find((board: any) => board.isActive);
      const col = board.columns.find(
        (col: any, i: any) => i === payload.colIndex
      );
      col.tasks = col.tasks.filter(
        (task: any, i: any) => i !== payload.taskIndex
      );
      board.columns = [
        ...board.columns.slice(0, payload.colIndex),
        col,
        ...board.columns.slice(payload.colIndex + 1),
      ];
      state.splice(state.indexOf(board), 1, board);
    },
  },
});

export default boardsSlice;
