import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ReactComponent as Logo } from '@images/logo.svg';
import styles from './style.module.scss';
import { Workspace } from '@entities/models/workspace';
import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import cn from 'classnames';

const WorkspaceList = () => {
  const { workspaces, switchWorkspace } = useWorkspaceContext();
  const navigate = useNavigate();

  const handlePickWorkspace = useCallback(
    (workspace: Workspace) => {
      switchWorkspace(workspace);
      navigate('/home');
    },
    [switchWorkspace],
  );

  return (
    <>
      <div className={styles.title}>Log in to Dokably</div>
      <div className={styles.subtitle}>
        Choose one of the workspaces you have access to:
      </div>
      <div className={styles.workspaceList}>
        {workspaces.map((item) => (
          <div
            key={uuid()}
            className={cn(
              styles.workspaceListItem,
              styles.workspaceListItem__black,
            )}
            onClick={() => handlePickWorkspace(item)}
          >
            <Logo />
            {item.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(WorkspaceList);
