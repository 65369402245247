export class Unit {
  id: string;
  name: string;
  type: UnitType;
  parentUnit: ParentUnit | null;
  color: string | null;
  isDefault?: boolean;
  createdAt?: Date;

  constructor(
    id: string,
    name: string,
    type: UnitType,
    parentUnit: ParentUnit | null,
    color: string | null,
    isDefault?: boolean,
    state?: boolean,
    createdAt?: Date
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.parentUnit = parentUnit;
    this.color = color;
    this.isDefault = isDefault;
    this.createdAt = createdAt;
  }
}

export interface ParentUnit {
  id?: string;
}

export const mapUnit = (data: any): Unit[] => {
  return data;
};

export type UnitType = 'document' | 'channel' | 'whiteboard' | 'task_board';
