import { getUnitPermission } from '@app/services/unit.service';
import { Unit } from '@entities/models/unit';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { Permission } from '@entities/models/unitPermissions';

export type UnitsPermissionsMapType = Record<Unit['id'], Permission[]>;

export type PermissionsContextValueType = {
  getUnitPermissionsAndUpdate: (unitId: string) => Promise<Permission[]>;
  fetchUnitItemPermissions: (unitId: string) => Promise<Permission[]>;
  updatePermissionsForUnits: (
    unitIds: string[],
  ) => Promise<UnitsPermissionsMapType>;
  getPermissionsForUnit: (unitId: string) => Permission[];
};

const PermissionsContext = createContext<PermissionsContextValueType>({
  getUnitPermissionsAndUpdate: async () => [],
  fetchUnitItemPermissions: async () => [],
  updatePermissionsForUnits: async () => ({}),
  getPermissionsForUnit: () => [],
} as PermissionsContextValueType);

export const PermissionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [unitPermissionsMap, setUnitPermissionsMap] =
    useState<UnitsPermissionsMapType>({});

  const fetchUnitItemPermissions = useCallback(async (unitId: string) => {
    try {
      const result = await getUnitPermission(unitId);
      return result.permissions;
    } catch (error) {
      console.error(
        'Error during fetching permissions: ',
        JSON.stringify(error),
      );
      return [];
    }
  }, []);

  const fetchPermissionsForUnitList = useCallback(
    async (unitIds: string[]): Promise<UnitsPermissionsMapType> => {
      const unitPermissionsPromises = unitIds.map((item) =>
        getUnitPermission(item),
      );

      try {
        const loadedUnitsPermissions = await Promise.all(
          unitPermissionsPromises,
        );
        const mapById = _.keyBy(loadedUnitsPermissions, (item) => item.id);

        const flattenMapById = _.mapValues(mapById, 'permissions');
        return flattenMapById;
      } catch (error) {
        console.error(
          'Error during fetching permissions: ',
          JSON.stringify(error),
        );
        return {};
      }
    },
    [],
  );

  const updatePermissionsForUnits = useCallback(
    async (unitIds: string[]) => {
      const unitIdToPermissionsMap = await fetchPermissionsForUnitList(unitIds);

      setUnitPermissionsMap((prevState) => ({
        ...prevState,
        ...unitIdToPermissionsMap,
      }));

      return unitIdToPermissionsMap;
    },
    [fetchPermissionsForUnitList],
  );

  const getUnitPermissionsAndUpdate = useCallback(
    async (unitId: string): Promise<Permission[]> => {
      const existingPermissions = unitPermissionsMap[unitId];

      if (existingPermissions) {
        return existingPermissions;
      }

      const fetchedPermissions = await fetchUnitItemPermissions(unitId);

      setUnitPermissionsMap((prevState) => ({
        ...prevState,
        [unitId]: fetchedPermissions,
      }));

      return fetchedPermissions;
    },
    [unitPermissionsMap, fetchUnitItemPermissions],
  );

  const getPermissionsForUnit = useCallback(
    (unitId: string) => {
      const permissionsForUnit = unitPermissionsMap[unitId] ?? [];
      return permissionsForUnit;
    },
    [unitPermissionsMap],
  );

  const contextValue = useMemo(
    () => ({
      getUnitPermissionsAndUpdate,
      fetchUnitItemPermissions,
      updatePermissionsForUnits,
      getPermissionsForUnit,
    }),
    [
      fetchUnitItemPermissions,
      updatePermissionsForUnits,
      getUnitPermissionsAndUpdate,
      getPermissionsForUnit,
    ],
  );

  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);

  if (typeof context === undefined) {
    throw new Error(
      'usePermissionsContext must be used within PermissionsContextProvider',
    );
  }

  return context;
};
