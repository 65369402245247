import { IDuplicateButton } from './DuplicateButton.types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { ReactComponent as Duplicate } from '@images/duplicate.svg';
import { useUnitsContext } from '@app/context/unitsContext/unitsContext';

const DuplicateButton = ({ unit, callback }: IDuplicateButton) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { duplicateUnit } = useUnitsContext();

  const handleDuplicate = async () => {
    const newUnit = await duplicateUnit(unit);

    _.delay(() => {
      navigate(`/workspace/${newUnit.id}`);
    }, 100);

    callback && callback();
  };

  return (
    <div
      className='action-item'
      onClick={handleDuplicate}
    >
      <Duplicate className='action-item__icon' />
      Duplicate
    </div>
  );
};

export default DuplicateButton;
