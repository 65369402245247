import { useWorkspaceContext } from '@app/context/workspaceContext/workspaceContext';
import Loading from '@shared/common/Loading';
import WorkspaceList from './WorkspaceList';
import AuthLayout from '@pages/layouts/AuthLayout/AuthLayout.component';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '@app/redux/features/userSlice';
import { useDispatch } from 'react-redux';

const WorkspaceListContainer = () => {
  const { workspaces, workspacesLoaded, switchWorkspace } =
    useWorkspaceContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate('/login');
  }, []);

  useEffect(() => {
    if (workspacesLoaded && workspaces.length === 1) {
      switchWorkspace(workspaces[0]);
      return navigate('/home');
    }
    if (workspacesLoaded && workspaces.length === 0) {
      return navigate('/new-workspace');
    }
  }, [workspaces, workspacesLoaded]);

  if (!workspaces?.length) {
    return <Loading />;
  }

  return (
    <AuthLayout>
      <WorkspaceList />
    </AuthLayout>
  );
};

export default WorkspaceListContainer;
