import { ReactComponent as SuccessIcon } from '../images/resolve.svg';

type ToastProps = {
  text: string;
};

export function SuccessToast({ text }: ToastProps) {
  return (
    <div className='py-4.5 px-4.7 flex items-center justify-center rounded bg-white'>
      <SuccessIcon className='[&>rect]:fill-primaryHover [&>rect]:stroke-primaryHover [&>path]:stroke-white w-4.7 h-4.7 mr-3.5' />
      <span className='font-medium text-sml'>{text}</span>
    </div>
  );
}

export function ErrorToast({ text }: ToastProps) {
  return (
    <div className='py-4.5 px-4.7 flex items-center justify-center rounded bg-[#FFECEE]'>
      <span className='font-medium text-sml'>{text}</span>
    </div>
  );
}

export function DarkTextToast({ text }: ToastProps) {
  return (
    <div className='py-3.5 px-4.5 flex items-center justify-center rounded bg-text90'>
      <span className='font-medium text-white text-sml'>{text}</span>
    </div>
  );
}
