import { useMemo } from 'react';
import './style.css';

interface IComingSoon {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  alternativeTitle?: string;
}

const ComingSoon = ({
  top,
  left,
  right,
  bottom,
  alternativeTitle,
}: IComingSoon) => {
  const style = useMemo(() => {
    const styleBuilder = {};
    if (top !== undefined) {
      Object(styleBuilder).top = top;
    }
    if (left !== undefined) {
      Object(styleBuilder).left = left;
    }
    if (right !== undefined) {
      Object(styleBuilder).right = right;
    }
    if (bottom !== undefined) {
      Object(styleBuilder).bottom = bottom;
    }
    return styleBuilder;
  }, [top, left, right, bottom]);
  return (
    <div className='coming-soon' style={style}>
      {alternativeTitle ?? 'Coming soon'}
    </div>
  );
};

export default ComingSoon;
