import { createChannel, getChannels } from '@app/services/channel.service';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChannelPrivacy, IChannel } from '@entities/models/IChannel';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@app/redux/features/userSlice';
import { useWorkspaceContext } from '../workspaceContext/workspaceContext';

const generateColor = () => {
  const hue = Math.floor(Math.random() * 359);
  return `hsl(${hue},50%,75%)`;
};

type CreateNewChannel = (
  workspaceId: string,
  channelName: string,
  privacy: ChannelPrivacy,
) => Promise<IChannel>;

export type ChannelsContextValueType = {
  channels: IChannel[];
  didChannelsLoaded: boolean;
  defaultChannel: IChannel | null;
  createNewChannel: CreateNewChannel;
};

const ChannelsContext = createContext<ChannelsContextValueType>({
  channels: [],
  didChannelsLoaded: false,
  defaultChannel: null,
  createNewChannel: async () => {},
} as unknown as ChannelsContextValueType);

export const ChannelsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { workspace } = useWorkspaceContext();
  const [didChannelsLoaded, setDidChannelsLoaded] = useState(false);
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [defaultChannel, setDefaultChannel] = useState<IChannel | null>(null);
  const userState = useSelector(selectCurrentUser);

  const createNewChannel: CreateNewChannel = useCallback(
    (workspaceId, channelName, privacy) => {
      const color = generateColor();
      return createChannel(workspaceId, channelName, color, privacy);
    },
    [],
  );

  const loadChannels = useCallback(async (workspaceId: string) => {
    if (!workspaceId) {
      return;
    }

    setDidChannelsLoaded(false);
    const loadedChannels = await getChannels(workspaceId);
    const defChannel = getDefaultChannel(loadedChannels);

    setDefaultChannel(defChannel);
    setChannels(loadedChannels);
    setDidChannelsLoaded(true);
  }, []);

  useEffect(() => {
    if (!workspace?.id) {
      return;
    }

    loadChannels(workspace?.id);
  }, [workspace?.id]);

  useEffect(() => {
    const createPrivateChannelForMemberIfNeeded = async () => {
      if (!didChannelsLoaded || !workspace?.id) {
        return;
      }

      const userRole = userState?.workspace?.userRole;

      if (!userRole || userRole !== 'member') {
        return;
      }

      const privateChannel = getPrivateChannel(channels);

      if (privateChannel) {
        return;
      }

      await createNewChannel(workspace?.id, 'Private', 'private');
      loadChannels(workspace?.id);
    };
    createPrivateChannelForMemberIfNeeded();
  }, [
    didChannelsLoaded,
    channels,
    userState?.workspace?.userRole,
    workspace?.id,
  ]);

  const getDefaultChannel = useCallback((channels: IChannel[]) => {
    if (channels.length === 0) {
      return null;
    }
    const defChannel =
      channels.find((channel) => channel.byDefault) ||
      getPrivateChannel(channels) ||
      channels[0];
    return defChannel;
  }, []);

  const getPrivateChannel = useCallback((channels: IChannel[]) => {
    return channels.find((channel) => channel.name.toLowerCase() === 'private');
  }, []);

  const contextValue = useMemo(
    () => ({ channels, didChannelsLoaded, defaultChannel, createNewChannel }),
    [channels, didChannelsLoaded, defaultChannel, createNewChannel],
  );

  return (
    <ChannelsContext.Provider value={contextValue}>
      {children}
    </ChannelsContext.Provider>
  );
};

export const useChannelsContext = () => {
  const context = useContext(ChannelsContext);

  if (typeof context === undefined) {
    throw new Error(
      'useChannelsContext must be used within ChannelsContextProvider',
    );
  }

  return context;
};
