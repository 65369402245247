import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  readOnly: false,
  update: 0,
  readOnlyDescription: false,
  updateDescription: 0,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setReadOnly(state, action) {
      state.readOnly = action.payload;
    },
    setUpdate(state, action) {
      state.update = state.update + action.payload;
    },
    setReadOnlyDescription(state, action) {
      state.readOnlyDescription = action.payload;
    },
    setUpdateDescription(state, action) {
      state.updateDescription = state.updateDescription + action.payload;
    },
  },
});

export const { setReadOnly, setUpdate, setReadOnlyDescription, setUpdateDescription } = commentsSlice.actions;
export default commentsSlice.reducer;
