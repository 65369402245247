import React from 'react';
import { WorkspaceContextProvider } from './workspaceContext/workspaceContext';
import { PermissionsContextProvider } from './permissionsContext/permissionsContext';
import { ChannelsContextProvider } from './channelsContext/channelsContext';
import { UnitsContextProvider } from './unitsContext/unitsContext';

type GeneralContextProviderProps = {
  children: React.ReactNode;
};

const GeneralContextProvider: React.FC<GeneralContextProviderProps> = ({
  children,
}) => {
  return (
    <WorkspaceContextProvider>
      <PermissionsContextProvider>
        <ChannelsContextProvider>
          <UnitsContextProvider>{children}</UnitsContextProvider>
        </ChannelsContextProvider>
      </PermissionsContextProvider>
    </WorkspaceContextProvider>
  );
};

export default GeneralContextProvider;
